const backgroundImage = {
    altText: 'Alt text',
    localFile: {
        childImageSharp: {
            gatsbyImageData: {
                layout: 'constrained',
                placeholder: {
                    fallback:
                        'data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wgARCAAIABQDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAb/xAAWAQEBAQAAAAAAAAAAAAAAAAADAAL/2gAMAwEAAhADEAAAAbmVEKdFr//EABkQAAMAAwAAAAAAAAAAAAAAAAEDEgIEE//aAAgBAQABBQLJz7gM1uEH/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAIRAQP/2gAIAQMBAT8B6tqSCtcP/8QAGBEBAAMBAAAAAAAAAAAAAAAAAQACAxH/2gAIAQIBAT8ByC6jLZV7P//EAB0QAAICAQUAAAAAAAAAAAAAAAECABEhAxITIkH/2gAIAQEABj8C2twoPaazFV+pc5zGCa4q5//EABsQAQACAgMAAAAAAAAAAAAAAAERIQAxUWFx/9oACAEBAAE/IbgJYVTgkI9yETv+lzeQN3Vn/9oADAMBAAIAAwAAABB3/wD/xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQMBAT8Q1zmFS//EABcRAAMBAAAAAAAAAAAAAAAAAAABIfD/2gAIAQIBAT8QSDqKPD//xAAaEAEBAQADAQAAAAAAAAAAAAABESEAMVFx/9oACAEBAAE/EE09qijAAnNc7PJHQ1hNCLnr7zUAEBfNmWB1z//Z'
                },
                backgroundColor: 'transparent',
                images: {
                    fallback: {
                        src: '/static/81cd7c81bd971d656ed2e018195cdb86/c84c2/sales-woman-banner-scaled.jpg',
                        srcSet: '/static/81cd7c81bd971d656ed2e018195cdb86/fbb2c/sales-woman-banner-scaled.jpg 576w,\n/static/81cd7c81bd971d656ed2e018195cdb86/b3c76/sales-woman-banner-scaled.jpg 768w,\n/static/81cd7c81bd971d656ed2e018195cdb86/e31a2/sales-woman-banner-scaled.jpg 992w,\n/static/81cd7c81bd971d656ed2e018195cdb86/87526/sales-woman-banner-scaled.jpg 1400w,\n/static/81cd7c81bd971d656ed2e018195cdb86/c84c2/sales-woman-banner-scaled.jpg 1500w',
                        sizes: '(min-width: 1500px) 1500px, 100vw'
                    },
                    sources: [
                        {
                            srcSet: '/static/81cd7c81bd971d656ed2e018195cdb86/aff6f/sales-woman-banner-scaled.avif 576w,\n/static/81cd7c81bd971d656ed2e018195cdb86/f7e03/sales-woman-banner-scaled.avif 768w,\n/static/81cd7c81bd971d656ed2e018195cdb86/1896e/sales-woman-banner-scaled.avif 992w,\n/static/81cd7c81bd971d656ed2e018195cdb86/119cd/sales-woman-banner-scaled.avif 1400w,\n/static/81cd7c81bd971d656ed2e018195cdb86/c98fb/sales-woman-banner-scaled.avif 1500w',
                            type: 'image/avif',
                            sizes: '(min-width: 1500px) 1500px, 100vw'
                        },
                        {
                            srcSet: '"/static/81cd7c81bd971d656ed2e018195cdb86/e9670/sales-woman-banner-scaled.webp 576w,\n/static/81cd7c81bd971d656ed2e018195cdb86/993c3/sales-woman-banner-scaled.webp 768w,\n/static/81cd7c81bd971d656ed2e018195cdb86/6a37f/sales-woman-banner-scaled.webp 992w,\n/static/81cd7c81bd971d656ed2e018195cdb86/8cd5b/sales-woman-banner-scaled.webp 1400w,\n/static/81cd7c81bd971d656ed2e018195cdb86/7270c/sales-woman-banner-scaled.webp 1500w',
                            type: 'image/webp',
                            sizes: '(min-width: 1500px) 1500px, 100vw'
                        }
                    ]
                },
                width: 1500,
                height: 570
            }
        }
    }
}

export default backgroundImage
